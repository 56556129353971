@charset "utf-8";

//-----------------------------------------------
// responsive setting
//-----------------------------------------------
$sp: "only screen and (max-width: 640px)";
$pc: "only screen and (min-width: 641px)";

/*===============================================
 responsive element
===============================================*/
.pc-inline {
	display: inline !important;
}
.pc-inline-block {
	display: inline-block !important;
}
.pc-block {
	display: block !important;
}
.sp-inline,
.sp-inline-block,
.sp-block {
	display: none !important;
}

@media #{$sp} {
	.pc-inline,
	.pc-inline-block,
	.pc-block {
		display: none !important;
	}
	.sp-inline {
		display: inline !important;
	}
	.sp-inline-block {
		display: inline-block !important;
	}
	.sp-block {
		display: block !important;
	}
}
