@charset "utf-8";

html {
	height: 100%;
	min-width: 1100px;
	overflow-y: scroll;
	
	@media #{$sp} {
		min-width: 0;
	}
}
body {
	height: 100%;
}

.alt {
	display: none;
}

.is-sp {
	position: fixed;
	left: -9999px;
	top: 0;
	display: none;
	
	@media #{$sp} {
		display: block;
		width: 1px;
		height: 1px;
	}
}

/*===============================================
 contents
===============================================*/
#top {
	background-color: #000;
	
	.inner {
		position: relative;
		width: 100%;
		
		.video-container {
			video {
				display: block;
				width: 100%;
				height: auto;
				opacity: 0; // default
			}
		}
		.images-container {
			position: relative;
			width: 100%;
			height: 0;
			padding-top: percentage( 1000 / $sp-base );
			
			img {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 100%;
				height: auto;
				opacity: 0; // default
			}
		}
		.canvas-container {
			position: absolute;
			left: 31px;
			right: 31px;
			top: 28px;
			bottom: 28px;
			border: 2px solid #5d5d5d;
			border-radius: 22px;
			overflow: hidden;
			background-color: rgba( 255,255,255,0.15 );
			
			@media #{$sp} {
				left: percentage( 30 / $sp-base );
				right: percentage( 30 / $sp-base );
				top: percentage( 30 / 1000 );
				bottom: percentage( 30 / 1000 );
				border-radius: #{(22 / $sp-base * 100)}vw;
			}
			
			canvas {
				position: absolute;
			}
			
			.line-l,
			.line-r,
			.line-t,
			.line-b {
				position: absolute;
				display: block;
				background-color: #5d5d5d;
			}
			.line-l,
			.line-r {
				top: 50%;
				width: percentage( 560 / 1338 );
				height: 2px;
				margin-top: -1px;
				
				@media #{$sp} {
					width: percentage( 138 / 580 );
				}
			}
			.line-t,
			.line-b {
				left: 50%;
				width: 2px;
				height: percentage( 156 / 622 );
				margin-left: -1px;
				
				@media #{$sp} {
					height: percentage( 230 / 944 );
				}
			}
			.line-l {
				left: 0;
			}
			.line-r {
				right: 0;
			}
			.line-t {
				top: 0;
			}
			.line-b {
				bottom: 0;
				
				@media #{$sp} {
					height: percentage( 26 / 944 );
					
					&:after {
						position: absolute;
						left: 0;
						top: #{(-260 / $sp-base * 100)}vw;
						display: block;
						width: 2px;
						height: #{(202 / $sp-base * 100)}vw;
						background-color: #5d5d5d;
						content: "";
					}
				}
			}
			.circle {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 568px;
				height: 568px;
				margin-left: #{(-568 / 2)}px;
				margin-top: #{(-568 / 2)}px;
				background: url("#{$img}mv-circle.png") no-repeat;
				
				@media #{$sp} {
					width: #{(548 / $sp-base * 100)}vw;
					height: #{(548 / $sp-base * 100)}vw;
					margin-left: #{(-548 / 2 / $sp-base * 100)}vw;
					margin-top: #{(-548 / 2 / $sp-base * 100)}vw;
					background-size: 100% 100%;
				}
			}
		}
		.title {
			position: absolute;
			left: 50%;
			top: 50%;
			display: block;
			width: 223px;
			height: 339px;
			margin: #{(-339 * 0.5)}px 0 0 #{(-223 * 0.5)}px;
			background: url("#{$img}logo.png") no-repeat center center;
			background-size: contain;
			
			@media #{$sp} {
				width: #{(249 / $sp-base * 100)}vw;
				height: #{(379 / $sp-base * 100)}vw;
				margin: #{(-379 * 0.5 / $sp-base * 100)}vw 0 0 #{(-249 / 2 / $sp-base * 100)}vw;
			}
		}
		.sns {
			position: absolute;
			// left: 1215px;
			right: 48px;
			top: 42px;
			width: 139px;
			
			@media #{$sp} {
				left: #{(208 / $sp-base * 100)}vw;
				top: #{(880 / $sp-base * 100)}vw;
				width: #{(223 / $sp-base * 100)}vw;
			}
			
			div {
				float: left;
				width: 43px;
				margin-left: 5px;
				
				@media #{$sp} {
					width: #{(69 / $sp-base * 100)}vw;
					margin-left: #{(8 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin-left: 0;
				}
				
				a {
					display: block;
					width: 43px;
					height: 42px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					
					@media #{$sp} {
						width: #{(69 / $sp-base * 100)}vw;
						height: #{(70 / $sp-base * 100)}vw;
					}
				}
				&.sns-tw {
					a {
						background-image: url("#{$img}sns-tw.png");
					}
				}
				&.sns-fb {
					a {
						background-image: url("#{$img}sns-fb.png");
					}
				}
				&.sns-hb {
					a {
						background-image: url("#{$img}sns-hb.png");
					}
				}
			}
		}
	}
}

#background {
	background-color: #fff;
	
	.inner {
		width: 900px;
		padding: 80px 0 106px;
		margin: 0 auto;
		
		@media #{$sp} {
			width: auto;
			padding: #{(90 / $sp-base * 100)}vw #{(30 / $sp-base * 100)}vw #{(110 / $sp-base * 100)}vw;
		}
		
		h2 {
			display: block;
			width: 173px;
			height: 59px;
			margin: 0 auto;
			background: url("#{$img}background-title-pc.png") no-repeat center center;
			
			@media #{$sp} {
				width: #{(254 / $sp-base * 100)}vw;
				height: #{(87 / $sp-base * 100)}vw;
				background-image: url("#{$img}background-title-sp.png");
				background-size: contain;
			}
		}
		.catch {
			margin-top: 40px;
			text-align: center;
			color: #5d5d5d;
			font-size: 36px;
			line-height: 42px;
			
			@media #{$sp} {
				margin-top: #{(60 / $sp-base * 100)}vw;
				font-size: #{(48 / $sp-base * 100)}vw;
				line-height: #{(72 / 48)};
			}
		}
		.text {
			margin-top: 40px;
			
			@media #{$sp} {
				margin-top: #{(36 / $sp-base * 100)}vw;
			}
			
			p {
				margin-top: 28px;
				color: #5d5d5d;
				font-size: 18px;
				line-height: 2;
				
				@media #{$sp} {
					margin-top: #{(36 / $sp-base * 100)}vw;
					font-size: #{(26 / $sp-base * 100)}vw;
					line-height: #{(40/26)};
				}
				
				&:first-child {
					margin-top: 0;
				}
				
				&.by {
					text-align: right;
				}
			}
		}
	}
}

#concept {
	position: relative;
	width: 100%;
	// height: 1198px;
	background: #020000 url("#{$img}concept-bg-pc.jpg") no-repeat center top;
	
	@media #{$sp} {
		background-image: url("#{$img}concept-bg-sp.jpg");
		background-size: 100% auto;
	}
	
	.inner {
		width: 900px;
		padding: 278px 0 0 0;
		margin: 0 auto;
		
		@media #{$sp} {
			width: auto;
			padding: #{(316 / $sp-base * 100)}vw 0 0;
		}
		
		h2 {
			text-align: center;
			color: #dd0012;
			font-size: 24px;
			line-height: 1;
			
			@media #{$sp} {
				font-size: #{(24 / $sp-base * 100)}vw;
			}
		}
		.catch {
			display: block;
			width: 553px;
			height: 46px;
			margin: 28px auto 0;
			background: url("#{$img}concept-catch-pc.png") no-repeat;
			
			@media #{$sp} {
				width: #{(415 / $sp-base * 100)}vw;
				height: #{(138 / $sp-base * 100)}vw;
				margin-top: #{(20 / $sp-base * 100)}vw;
				background-image: url("#{$img}concept-catch-sp.png");
				background-size: contain;
			}
		}
		.text {
			margin-top: 32px;
			
			@media #{$sp} {
				margin-top: #{(28 / $sp-base * 100)}vw;
			}
			
			p {
				text-align: center;
				color: #000;
				font-size: 24px;
				line-height: 2;
				
				@media #{$sp} {
					font-size: #{(28 / $sp-base * 100)}vw;
					line-height: #{(48 / 30)};
				}
			}
		}
	}
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url("#{$img}concept-light-pc.png") no-repeat center top;
		content: "";
		
		@media #{$sp} {
			background-image: url("#{$img}concept-light-sp.png");
			background-size: 100% auto;
		}
	}
}

#step {
	margin-top: 300px;
	
	@media #{$sp} {
		margin-top: #{(320 / $sp-base * 100)}vw;
	}
	
	h2 {
		text-align: center;
		color: #fff;
		font-size: 30px;
		line-height: 1;
		
		@media #{$sp} {
			font-size: #{(36 / $sp-base * 100)}vw;
		}
	}
	.steps {
		position: relative;
		width: 900px;
		height: 414px;
		background: url("#{$img}step-steps-pc.png") no-repeat;
		margin: 42px auto 0;
		
		@media #{$sp} {
			width: #{(581 / $sp-base * 100)}vw;
			height: #{(839 / $sp-base * 100)}vw;
			background-image: url("#{$img}step-steps-sp.png");
			background-size: contain;
			margin-top: #{(40 / $sp-base * 100)}vw;
		}
	}
	.film {
		width: 1144px;
		height: 2530px;
		margin: -8px auto 0;
		background: url("#{$img}step-film-pc.png") no-repeat;
		
		@media #{$sp} {
			width: 100%;
			height: #{(2568 / $sp-base * 100)}vw;
			margin-top: #{(-28 / $sp-base * 100)}vw;
			background-image: url("#{$img}step-film-sp.png");
			background-size: contain;
		}
	}
	.start {
		padding: 88px 0 488px;
		
		@media #{$sp} {
			padding: #{(80 / $sp-base * 100)}vw 0 #{(438 / $sp-base * 100)}vw;
		}
		
		p {
			text-align: center;
			color: #fff;
			font-size: 36px;
			line-height: 60px;
			
			@media #{$sp} {
				font-size: #{(36 / $sp-base * 100)}vw;
				line-height: #{(60 / 36)};
			}
		}
	}
}
#screen-1,
#screen-2 {
	.inner {
		padding-top: 78px;
		
		@media #{$sp} {
			padding-top: #{(78 / $sp-base * 100)}vw;
		}
		
		h2 {
			display: block;
			width: 224px;
			height: 64px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: #{(224 / $sp-base * 100)}vw;
				height: #{(64 / $sp-base * 100)}vw;
			}
		}
		.catch {
			height: #{(210 - 24)}px;
			padding-top: 24px;
			text-align: center;
			color: #fff;
			font-size: 24px;
			line-height: 1;
			
			@media #{$sp} {
				height: #{((248 - 26 - 1) / $sp-base * 100)}vw;
				padding-top: #{(26 / $sp-base * 100)}vw;
				font-size: #{(30 / $sp-base * 100)}vw;
				line-height: #{(48 / 30)};
			}
		}
		.poster {
			width: 1000px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: #{(( 581 + 1 ) / $sp-base * 100)}vw;
			}
			
			a,
			img {
				display: block;
			}
			
			a {
				position: relative;
				cursor: default;
				
				@media #{$sp} {
					cursor: pointer;
					
					&:after {
						position: absolute;
						right: #{(20 / $sp-base * 100)}vw;
						bottom: #{(26 / $sp-base * 100)}vw;
						display: block;
						width: #{(53 / $sp-base * 100)}vw;
						height: #{(51 / $sp-base * 100)}vw;
						background: url("#{$img}poster-zoom.png") no-repeat;
						background-size: 100% 100%;
						content: "";
					}
				}
			}
			img {
				@media #{$sp} {
					width: 100%;
					height: auto;
				}
			}
		}
		.outline {
			margin-top: 56px;
			
			@media #{$sp} {
				margin-top: #{(115 / $sp-base * 100)}vw;
			}
			
			h3 {
				text-align: center;
				color: #fff;
				font-size: 24px;
				line-height: 1;
				
				@media #{$sp} {
					font-size: #{(36 / $sp-base * 100)}vw;
				}
				
				&:after {
					display: block;
					width: 214px;
					height: 2px;
					margin: 14px auto 0;
					background-color: #fff;
					content: "";
					
					@media #{$sp} {
						width: #{(322 / $sp-base * 100)}vw;
						height: 1px;
						margin-top: #{(12 / $sp-base * 100)}vw;
					}
				}
			}
			.text {
				width: 904px;
				margin: 24px auto 0;
				
				@media #{$sp} {
					width: #{(580 / $sp-base * 100)}vw;
				}
				
				p {
					color: #fff;
					font-size: 18px;
					line-height: 2;
					
					@media #{$sp} {
						font-size: #{(26 / $sp-base * 100)}vw;
						line-height: #{(48 / 26)};
					}
				}
			}
		}
	}
	.cast {
		padding-top: 75px;
		background: url("#{$img}screen-cast-bg-pc.png") no-repeat center top;
		
		@media #{$sp} {
			padding-top: #{(70 / $sp-base * 100)}vw;
			background-image: url("#{$img}screen-cast-bg-sp.png");
			background-size: 100% auto;
		}
		
		.cast-inner {
			position: relative;
			width: 707px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: #{(595 / $sp-base * 100)}vw;
				// height: #{(1566 / $sp-base * 100)}vw;
			}
			.cast-content {
				width: 707px;
				
				@media #{$sp} {
					width: #{(595 / $sp-base * 100)}vw;
				}
			}
			.cast-comment-container {
				width: 707px;
				padding: 36px 0;
				
				@media #{$sp} {
					width: #{(595 / $sp-base * 100)}vw;
					padding: #{(56 / $sp-base * 100)}vw 0;
				}
				
				.cast-comment {
					width: 600px;
					margin: 0 auto;
					border-radius: 4px;
					box-sizing: border-box;
					
					@media #{$sp} {
						width: #{(520 / $sp-base * 100)}vw;
						border-radius: #{(4 / $sp-base * 100)}vw;
					}
					
					.label {
						cursor: pointer;
						
						&:hover {
							opacity: 0.7;
						}
						.arw {
							padding: 11px 0;
							background-color: #fff;
							text-align: center;
							font-size: 18px;
							line-height: 1;
							
							@media #{$sp} {
								padding: #{(6 / $sp-base * 100)}vw 0;
								font-size: #{(24 / $sp-base * 100)}vw;
							}
							
							.close {
								display: none;
							}
							
							&:after {
								display: inline-block;
								width: 18px;
								height: 16px;
								margin-left: 0.5em;
								vertical-align: -0.1em;
								transition: transform .2s ease-out;
								transform: rotate( 0deg );
								content: "";
								
								@media #{$sp} {
									width: #{(22 / 24)}em;
									height: #{(20 / 24)}em;
									vertical-align: 0;
								}
							}
						}
					}
					
					&.expanded .label {
						.arw {
							.close {
								display: inline;
							}
							.open {
								display: none;
							}
							
							&:after {
								transform: rotate( -180deg );
							}
						}
					}
					.detail {
						padding-top: 4px;
						display: none; // default
						
						.detail-inner {
							padding: 30px 32px;
							background-color: #fff;
							
							@media #{$sp} {
								padding: #{(28 / $sp-base * 100)}vw #{(30 / $sp-base * 100)}vw;
							}
							
							p {
								font-size: 18px;
								line-height: #{(32 / 18)};
								
								@media #{$sp} {
									font-size: #{(24 / $sp-base * 100)}vw;
									line-height: #{(36 / 24)};
								}
								
								&.question {
									margin-top: 40px;
									color: #172987;
									
									@media #{$sp} {
										margin-top: #{(48 / $sp-base * 100)}vw;
									}
									
									&:first-child {
										margin-top: 0;
									}
								}
								&.answer {
									color: #5d5d5d;
								}
							}
						}
					}
				}
			}
		}
	}
}
#screen-1 {
	background: url("#{$img}screen-1-bg-pc.jpg") no-repeat center top;
	
	@media #{$sp} {
		background-image: url("#{$img}screen-1-bg-sp.jpg");
		background-size: 100% auto;
	}
	
	.inner {
		h2 {
			background: url("#{$img}screen-1-title-pc.png") no-repeat;
			
			@media #{$sp} {
				background-size: contain;
			}
		}
		.outline {
			height: 315px;
			
			@media #{$sp} {
				height: #{(570 / $sp-base * 100)}vw;
			}
		}
	}
	.cast {
		.cast-inner {
			.cast-content {
				height: 639px;
				background: url("#{$img}screen-1-cast-pc.png") no-repeat;
				
				@media #{$sp} {
					height: #{(1230 / $sp-base * 100)}vw;
					background-image: url("#{$img}screen-1-cast-sp.png");
					background-size: 100% auto;
				}
			}
			.cast-comment-container {
				background: url("#{$img}screen-1-cast-link-bg-pc.png") no-repeat center top;
				
				@media #{$sp} {
					background-image: url("#{$img}screen-1-cast-link-bg-sp.png");
					background-size: 100% auto;
				}
				
				.cast-comment {
					padding: 4px 23px 23px;
					background-color: #172987;
					
					@media #{$sp} {
						padding: #{(6 / $sp-base * 100)}vw #{(20 / $sp-base * 100)}vw #{(16 / $sp-base * 100)}vw;
					}
					
					.label {
						.image {
							width: 554px;
							height: 133px;
							background: url("#{$img}screen-1-cast-link-pc.png") no-repeat;
							
							@media #{$sp} {
								width: #{(490 / $sp-base * 100)}vw;
								height: #{(149 / $sp-base * 100)}vw;
								background-image: url("#{$img}screen-1-cast-link-sp.png");
								background-size: 100% auto;
							}
						}
						.arw {
							color: #172987;
							
							&:after {
								background: url("#{$img}screen-1-cast-link-arw.png") no-repeat;
								background-size: 100% 100%;
							}
						}
					}
				}
			}
		}
	}
}
#screen-2 {
	background: url("#{$img}screen-2-bg-pc.jpg") no-repeat center top;
	
	@media #{$sp} {
		background-image: url("#{$img}screen-2-bg-sp.jpg");
		background-size: 100% auto;
	}
	
	.inner {
		h2 {
			background: url("#{$img}screen-2-title-pc.png") no-repeat;
			
			@media #{$sp} {
				background-size: contain;
			}
		}
		.outline {
			height: 350px;
			
			@media #{$sp} {
				height: #{(764 / $sp-base * 100)}vw;
			}
		}
	}
	.cast {
		.cast-inner {
			.cast-content {
				height: 643px;
				background: url("#{$img}screen-2-cast-pc.png") no-repeat;
				
				@media #{$sp} {
					height: #{(1188 / $sp-base * 100)}vw;
					background-image: url("#{$img}screen-2-cast-sp.png");
					background-size: 100% auto;
				}
			}
			.cast-comment-container {
				background: url("#{$img}screen-2-cast-link-bg-pc.png") no-repeat center top;
				
				@media #{$sp} {
					background-image: url("#{$img}screen-2-cast-link-bg-sp.png");
					background-size: 100% auto;
				}
				
				.cast-comment {
					padding: 10px 23px 23px;
					background-color: #c30d22;
					
					@media #{$sp} {
						padding: #{(9 / $sp-base * 100)}vw #{(20 / $sp-base * 100)}vw #{(16 / $sp-base * 100)}vw;
					}
					
					.label {
						.image {
							width: 554px;
							height: 152px;
							background: url("#{$img}screen-2-cast-link-pc.png") no-repeat;
							
							@media #{$sp} {
								width: #{(482 / $sp-base * 100)}vw;
								height: #{(201 / $sp-base * 100)}vw;
								background-image: url("#{$img}screen-2-cast-link-sp.png");
								background-size: 100% auto;
							}
						}
						.arw {
							color: #c30d22;
							
							&:after {
								background: url("#{$img}screen-2-cast-link-arw.png") no-repeat;
								background-size: 100% 100%;
							}
						}
					}
				}
			}
			/*
			.link {
				left: 53px;
				top: 679px;
				width: 600px;
				height: 220px;
				
				@media #{$sp} {
					left: #{( 37 / $sp-base * 100 )}vw;
					top: #{( 1233 / $sp-base * 100 )}vw;
					width: #{( 520 / $sp-base * 100 )}vw;
					height: #{( 261 / $sp-base * 100 )}vw;
				}
				
				a {
					background: url("#{$img}screen-2-cast-link-pc.png") no-repeat;
					
					@media #{$sp} {
						background-image: url("#{$img}screen-2-cast-link-sp.png");
						background-size: contain;
					}
				}
			}
			*/
		}
	}
}

#interview {
	background: url("#{$img}interview-bg-pc.jpg") no-repeat center top;
	
	@media #{$sp} {
		background-image: url("#{$img}interview-bg-sp.jpg");
		background-size: 100% auto;
	}
	
	.inner {
		width: 900px;
		height: #{(1780 - 98)}px;
		padding: 98px 0 0 0;
		margin: 0 auto;
		
		@media #{$sp} {
			width: #{(580 / $sp-base * 100)}vw;
			height: #{(2904 / $sp-base * 100)}vw;
			padding-top: #{(80 / $sp-base * 100)}vw;
		}
		
		h2 {
			text-align: center;
			color: #fff;
			font-size: 50px;
			line-height: 1;
			
			@media #{$sp} {
				font-size: #{(36 / $sp-base * 100)}vw;
			}
		}
		> p {
			margin-top: 24px;
			text-align: center;
			color: #fff;
			font-size: 24px;
			line-height: 1;
			
			@media #{$sp} {
				margin-top: #{(20 / $sp-base * 100)}vw;
				font-size: #{(26 / $sp-base * 100)}vw;
			}
		}
		.interview-container {
			margin-top: 92px;
			
			@media #{$sp} {
				margin-top: #{(70 / $sp-base * 100)}vw;
			}
			
			.interview-item {
				position: relative;
				margin-top: 40px;
				background-color: #fff;
				
				@media #{$sp} {
					margin-top: #{(24 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin-top: 0;
				}
				
				.catch,
				.name,
				.answer {
					color: #5d5d5d;
				}
				.question {
					color: #172987;
				}
				
				&:nth-child(1) {
					.pic {
						float: right;
						
						@media #{$sp} {
							float: none;
							
							img {
								display: block;
								width: 100%;
								height: auto;
							}
						}
					}
					.interview-inner {
						float: left;
						width: 546px;
						padding: 50px 0 0 50px;
						box-sizing: border-box;
						
						@media #{$sp} {
							float: none;
							width: 100%;
							padding: #{(48 / $sp-base * 100)}vw #{(30 / $sp-base * 100)}vw;
						}
						
						.catch {
							font-size: 32px;
							line-height: 56px;
							
							@media #{$sp} {
								font-size: #{(36 / $sp-base * 100)}vw;
								line-height: #{(59 / 36)};
							}
						}
						.comment {
							margin-top: 32px;
							
							@media #{$sp} {
								margin-top: #{(48 / $sp-base * 100)}vw;
								padding-top: #{(48 / $sp-base * 100)}vw;
								border-top: 1px solid #5d5d5d;
							}
							
							.name {
								font-size: 16px;
								line-height: 1;
								
								@media #{$sp} {
									font-size: #{(24 / $sp-base * 100)}vw;
								}
								
								&:after {
									display: block;
									width: 450px;
									height: 1px;
									margin-top: 40px;
									background-color: #5d5d5d;
									content: "";
									
									@media #{$sp} {
										display: none;
									}
								}
							}
							.answer {
								margin-top: 32px;
								width: 450px;
								font-size: 15px;
								line-height: 2;
								
								@media #{$sp} {
									margin-top: #{(32 / $sp-base * 100)}vw;
									width: auto;
									font-size: #{(24 / $sp-base * 100)}vw;
									line-height: #{(36 / 24)};
								}
							}
						}
						
					}
					&:after {
						clear: both;
						display: block;
						content: "";
					}
				}
				&:nth-child(2),
				&:nth-child(3) {
					padding: 42px 0 40px 0;
					
					@media #{$sp} {
						padding: #{(48 / $sp-base * 100)}vw #{(30 / $sp-base * 100)}vw #{(64 / $sp-base * 100)}vw;
					}
					
					.pic {
						position: absolute;
						bottom: 0;
						
						@media #{$sp} {
							float: left;
							position: relative;
						}
						
						img {
							display: block;
							
							@media #{$sp} {
								width: 100%;
								height: auto;
							}
						}
					}
					.catch {
						text-align: center;
						font-size: 24px;
						line-height: 1;
						
						@media #{$sp} {
							text-align: left;
							font-size: #{(28 / $sp-base * 100)}vw;
							line-height: #{(40 / 30)};
						}
					}
					.comment {
						width: 600px;
						margin: 40px 0 0 250px;
						
						@media #{$sp} {
							width: auto;
							padding-top: #{(40 / $sp-base * 100)}vw;
							border-top: 1px solid #5d5d5d;
							margin: 0;
							clear: both;
						}
						
						p {
							font-size: 15px;
							line-height: 2;
							
							@media #{$sp} {
								font-size: #{(24 / $sp-base * 100)}vw;
								line-height: #{(36 / 24)};
							}
						}
						.question {
							margin-top: 16px;
							
							@media #{$sp} {
								margin-top: #{(20 / $sp-base * 100)}vw;
							}
						}
						.answer {
							margin-top: 24px;
							
							@media #{$sp} {
								margin-top: #{(20 / $sp-base * 100)}vw;
							}
						}
					}
				}
				&:nth-child(2) {
					.pic {
						left: 46px;
						
						@media #{$sp} {
							left: 0;
							width: #{(128 / $sp-base * 100)}vw;
							margin-right: #{(16 / $sp-base * 100)}vw;
							margin-top: #{(-32 / $sp-base * 100)}vw;
						}
					}
				}
				&:nth-child(3) {
					.pic {
						left: 38px;
						
						@media #{$sp} {
							left: 0;
							width: #{(122 / $sp-base * 100)}vw;
							margin-right: #{(24 / $sp-base * 100)}vw;
							margin-top: #{(-16 / $sp-base * 100)}vw;
						}
					}
				}
			}
		}
	}
}

#recruit {
	background: url("#{$img}recruit-bg-pc.jpg") no-repeat center top;
	
	@media #{$sp} {
		background-color: #070707;
		background-image: url("#{$img}recruit-bg-sp.jpg");
		background-size: 100% auto;
	}
	
	.inner {
		width: 900px;
		height: #{(1676 - 140)}px;
		padding: 140px 0 0 0;
		margin: 0 auto;
		
		@media #{$sp} {
			width: #{(580 / $sp-base * 100)}vw;
			height: auto;
			padding: #{(100 / $sp-base * 100)}vw 0 #{(140 / $sp-base * 100)}vw;
		}
		
		h2 {
			text-align: center;
			color: #fff;
			font-size: 50px;
			line-height: 1;
			
			@media #{$sp} {
				font-size: #{(50 / $sp-base * 100)}vw;
				line-height: #{(70 / 50)};
			}
		}
		> p {
			margin-top: 48px;
			
			@media #{$sp} {
				margin-top: #{(48 / $sp-base * 100)}vw;
			}
		}
		p {
			color: #fff;
			font-size: 18px;
			line-height: 2;
			
			@media #{$sp} {
				font-size: #{(26 / $sp-base * 100)}vw;
				line-height: #{(40 / 26)};
			}
			
			a {
				color: #fff;
				
				&:hover {
					color: #bbb;
				}
			}
		}
		.posters {
			width: 800px;
			margin: 56px auto 0;
			
			@media #{$sp} {
				width: auto;
				margin-top: #{(56 / $sp-base * 100)}vw;
			}
			
			img {
				float: left;
				display: block;
				margin-left: 20px;
				
				@media #{$sp} {
					width: #{(278 / $sp-base * 100)}vw;
					margin-left: #{(22 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin-left: 0;
				}
			}
			&:after {
				clear: both;
				display: block;
				content: "";
			}
		}
		.message {
			margin-top: 56px;
			
			@media #{$sp} {
				margin-top: #{(48 / $sp-base * 100)}vw;
			}
			
			p {
				margin-top: 40px;
				
				@media #{$sp} {
					margin-top: #{(48 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin-top: 0;
				}
			}
		}
		.banner {
			width: 496px;
			margin: 80px auto 0;
			
			@media #{$sp} {
				width: #{(520 / $sp-base * 100)}vw;
				margin-top: #{(90 / $sp-base * 100)}vw;
			}
			
			a,
			img {
				display: block;
			}
			a {
				transition: transform .2s ease-out;
				
				&:hover {
					transform: scale( 1.05 );
				}
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.footer {
	padding: 118px 0 24px;
	background-color: #fff;
	
	@media #{$sp} {
		padding: #{(110 / $sp-base * 100)}vw 0 #{(80 / $sp-base * 100)}vw;
	}
	
	.inner {
		width: 900px;
		margin: 0 auto;
		
		@media #{$sp} {
			width: #{(520 / $sp-base * 100)}vw;
		}
		
		h2 {
			width: 496px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: #{(520 / $sp-base * 100)}vw;
			}
			
			a,
			img {
				display: block;
			}
			a {
				transition: transform .2s ease-out;
				
				&:hover {
					transform: scale( 1.05 );
				}
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		> p {
			margin-top: 60px;
			color: #5d5d5d;
			font-size: 14px;
			line-height: #{(24/14)};
			
			@media #{$sp} {
				margin-top: #{(60 / $sp-base * 100)}vw;
			}
		}
		.produce {
			margin-top: 56px;
			text-align: center;
			
			@media #{$sp} {
				margin-top: #{(88 / $sp-base * 100)}vw;
			}
			
			a,
			img {
				display: block;
			}
			a {
				&:hover {
					opacity: 0.7;
				}
				img {
					width: 100%;
					height: auto;
				}
			}
			
			p {
				font-size: 18px;
				line-height: 1;
				
				@media #{$sp} {
					font-size: #{(20 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin-top: 0;
				}
				
				&.logo {
					width: 81px;
					margin: 24px auto 0;
					
					@media #{$sp} {
						width: #{(108 / $sp-base * 100)}vw;
						margin-top: #{(24 / $sp-base * 100)}vw;
					}
				}
			}
		}
		.copyright {
			text-align: center;
			color: #231815;
			
			@media #{$sp} {
				font-size: #{(20 / $sp-base * 100)}vw;
			}
		}
	}
}

/*===============================================
 modal
===============================================*/
@media #{$sp} {
	.modal {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		min-height: 100%;
		background-color: #c0c1c1;
		display: none; // default
		
		.modal-content {
			padding: #{(30 / $sp-base * 100)}vw #{(30 / $sp-base * 100)}vw #{(132 / $sp-base * 100)}vw;
			
			img {
				display: block;
				width: 100%;
			}
		}
		.modal-close {
			position: absolute;
			right: #{(30 / $sp-base * 100)}vw;
			bottom: #{(30 / $sp-base * 100)}vw;
			display: block;
			width: #{(60 / $sp-base * 100)}vw;
			height: #{(60 / $sp-base * 100)}vw;
			background: url("#{$img}poster-zoom-close.png") no-repeat;
			background-size: 100% 100%;
			content: "";
			cursor: pointer;
		}
	}
	body.modal-visible {
		#wrap {
			display: none;
		}
		.modal {
			position: relative;
		}
	}
}
@media #{$pc} {
	.modal {
		display: none !important;
	}
}