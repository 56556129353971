@charset "utf-8";

//-----------------------------------------------
// pos
//-----------------------------------------------
@mixin pos( $pos_type, $pos_point, $pos_h, $pos_v ) {
	position: $pos_type;
	
	@if $pos_point == "lt" {
		left: $pos_h;
		top: $pos_v;
	}
	@if $pos_point == "lb" {
		left: $pos_h;
		bottom: $pos_v;
	}
	@if $pos_point == "rt" {
		right: $pos_h;
		top: $pos_v;
	}
	@if $pos_point == "rb" {
		right: $pos_h;
		bottom: $pos_v;
	}
}

//-----------------------------------------------
// block
//-----------------------------------------------
@mixin block( $width, $height, $inline:false ) {
	@if $inline == false {
		display: block;
	}
	@if $inline == true {
		display: inline-block;
	}
	width: $width;
	height: $height;
}

//-----------------------------------------------
// liquid element
//-----------------------------------------------
@mixin liquid( $width, $height, $parent_width, $parent_height:null ) {
	display: block;
	
	@if ( unitless($width) ) {
		width: 100% * ( $width / $parent_width );
	}
	@else {
		width: $width;
	}
	
	@if ( unitless($height) ) {
		@if ( $parent_height ) {
			height: 100% * ( $height / $parent_height );
		}
		@else {
			height: 0;
			padding-top: 100% * ( $height / $parent_width );
		}
	}
	@else {
		height: 0;
		padding-top: $height;
	}
}

//-----------------------------------------------
// img
//-----------------------------------------------
@mixin img( $url ) {
	background: url( "#{$url}" ) no-repeat;
	background-size: 100% 100%;
	
	span, strong {
		display: none;
	}
}

//-----------------------------------------------
// hover
//-----------------------------------------------
@mixin hover( $opacity:.7 ) {
	$ie: $opacity * 100;
	
	transition: opacity .2s linear;
	opacity: 1;
	
	&:hover {
		opacity: $opacity;
		-ms-filter: "alpha(opacity=#{$ie})";
	}
}

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
	*zoom: 1;
	
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
